import React from 'react';
import styles from './badge.module.scss';

interface IBadge {
  number: number;
  max: number;
}

const Badge = ({ number, max }: IBadge) => {
  let countNumber = '';

  if (number > 0 && number <= max) {
    countNumber = number.toString();
  } else if (number > max) {
    countNumber = `${max}+`;
  }

  return (
    <span
      className={`badge bg-danger rounded-pill ${
        number > 0 && number <= max ? '' : `${styles['rounded-pill-2']}`
      }`}
    >
      {countNumber}
    </span>
  );
};

export default Badge;
