import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import NotificationDropdown from '../notification-dropdown';
import ProfileMenu from '../profile-menu';
import styles from './header.module.scss';
import { routes } from '../../constants/routes';

const LanguageDropdownWithNoSSR = dynamic(
  () => import('../language-dropdown'),
  { ssr: false },
);

interface HeaderProps {
  openSidebar: () => void;
}

const Header = ({ openSidebar }: HeaderProps) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState(false);

  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex flex-grow-1">
          <button
            type="button"
            onClick={() => openSidebar()}
            className={`${styles['menu-btn']} btn btn-sm px-3 font-size-16 header-item waves-effect`}
            id="vertical-menu-btn"
          >
            <i className="fa fa-fw fa-bars" />
          </button>

          <Link href={routes.dashboard.path()}>
            <div className={styles['navbar-logo']}>
              <img src="/assets/images/logo.png" alt="" />
            </div>
          </Link>

          <form className="app-search d-none mx-auto hidden">
            <div className="position-relative">
              <input
                type="text"
                className="form-control"
                placeholder={t('header.placeholderSearch')}
              />
              <span className="bx bx-search-alt" />
            </div>
          </form>
        </div>

        <div className="d-flex">
          <div className="dropdown d-inline-block d-lg-none ms-2">
            <button
              onClick={() => {
                setSearch(!search);
              }}
              type="button"
              className={`${styles['dropdown-search-btn']} btn header-item noti-icon waves-effect`}
              id="page-header-search-dropdown"
              hidden
            >
              <i className="mdi mdi-magnify" />
            </button>
            <div
              className={
                search
                  ? 'dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show'
                  : 'dropdown-menu dropdown-menu-lg dropdown-menu-right p-0'
              }
              aria-labelledby="page-header-search-dropdown"
            >
              <form className="p-3">
                <div className="form-group m-0">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search ..."
                      aria-label="Recipient's username"
                    />
                    <div className="input-group-append">
                      <button className="btn btn-primary" type="submit">
                        <i className="mdi mdi-magnify" />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <LanguageDropdownWithNoSSR />
          <NotificationDropdown />
          <ProfileMenu />
        </div>
      </div>
    </header>
  );
};

export default Header;
