import React, { useContext, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import avatarInitials from '../../helpers/avatar-name';
import profileInitials from '../../helpers/profile-initials';
import styles from './profile-menu.module.scss';
import { SessionContext } from '../../context/session';
import { logOutUser } from '../../helpers/log-out';

const ProfileMenu = () => {
  const [menu, setMenu] = useState(false);
  const { t } = useTranslation();
  const { session } = useContext(SessionContext);
  const lastName = session?.existingUser?.lastName;
  const firstName = session?.existingUser?.firstName;

  return (
    <Dropdown
      show={menu}
      onToggle={() => setMenu(!menu)}
      className="d-inline-block"
    >
      <Dropdown.Toggle
        className={`${styles['dropdown-btn']} btn header-item waves-effect`}
        id="page-header-user-dropdown"
        as="button"
      >
        <div className={styles['profile-information']}>
          <div className={styles['profile-avatar']}>
            {avatarInitials(firstName, lastName)}
          </div>
          <div>
            <span className="ms-2 me-1 d-sm-inline d-none">
              {profileInitials(firstName, lastName)}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </div>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-end">
        <Dropdown.Item>
          <Link href="/user">
            <div>
              <i className="bx bx-user font-size-16 align-middle me-1" />
              <span>{t('profileMenu.profile')}</span>
            </div>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item as="a" href="/crypto-wallet" hidden>
          <i className="bx bx-wallet font-size-16 align-middle me-1" />
          {t('profileMenu.myWallet')}
        </Dropdown.Item>
        <Dropdown.Item as="a" href="#" hidden>
          <span className="badge bg-success float-end">11</span>
          <i className="bx bx-wrench font-size-16 align-middle me-1" />
          {t('profileMenu.settings')}
        </Dropdown.Item>
        <Dropdown.Item>
          <Link href="/tenant-information">
            <div>
              <i className="bx bx-wrench font-size-16 align-middle me-1" />
              <span>{t('profileMenu.organizationSettings')}</span>
            </div>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item as="a" href="auth-lock-screen" hidden>
          <i className="bx bx-lock-open font-size-16 align-middle me-1" />
          {t('profileMenu.lockScreen')}
        </Dropdown.Item>
        <div className="dropdown-divider" />
        <Dropdown.Item as="a" onClick={logOutUser}>
          <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
          {t('profileMenu.logout')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileMenu;
