import { useEffect, useState } from 'react';
import { FirebaseApiInstance } from '../api/firebase';
import { ICommandResponse } from '../shared/interfaces';

export const useCommandRegister = (userID: string) => {
  const [commands, setCommands] = useState<ICommandResponse[]>([]);
  const [commandsCount, setCommandsCount] = useState<number>(0);

  const updateCommands = async () => {
    await FirebaseApiInstance.updateCommand(commands, userID);
    setCommands([]);
    setCommandsCount(0);
  };
  const getCommandSubscription = () => {
    try {
      FirebaseApiInstance.getCommandSubscription(userID).on(
        'value',
        (snapshot) => {
          console.log(snapshot);
          if (snapshot.val()) {
            const result: ICommandResponse[] = Object.keys(snapshot.val()).map(
              (key) =>
                ({
                  id: key,
                  ...snapshot.val()[key],
                } as ICommandResponse),
            );

            setCommandsCount(result.length);
            setCommands(result.sort((a, b) => b.executedDate - a.executedDate));
          }
        },
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCommandSubscription();
    return () => {
      FirebaseApiInstance.getCommandSubscription(userID).off('value');
    };
  }, [FirebaseApiInstance.userAuthStatus]);

  return {
    commands,
    commandsCount,
    updateCommands,
  };
};
