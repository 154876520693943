import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { IRoute } from '../../interfaces/routes';
import styles from './sidebar-menu-item.module.scss';

interface ISidebarMenuItemLink {
  route: IRoute;
  onCloseSidebar: () => void;
}

const SidebarMenuItemLink = ({
  route,
  onCloseSidebar,
}: ISidebarMenuItemLink) => {
  const [isOpenSubMenu, setIsOpenSubMenu] = useState(false);
  const { t } = useTranslation();
  const router = useRouter();

  const changeIsOpenSubMenu = () => setIsOpenSubMenu((prev) => !prev);

  if (route.path) {
    const path = route.path();
    const isExactLinkMatching = path !== '/' && router.asPath.includes(path);
    return (
      <li
        key={route.title}
        className={`${styles['menu-item']} ${
          isExactLinkMatching ? styles['menu-item-active'] : ''
        }`}
        onClick={onCloseSidebar}
      >
        <Link href={path}>
          <a className="waves-effect">
            {route.icon && <i className={route.icon} />}
            <span>{t(`${route.title}`)}</span>
          </a>
        </Link>
      </li>
    );
  }
  return (
    <li className={isOpenSubMenu ? 'mm-active' : ''}>
      <a
        className={`waves-effect has-arrow
                          ${styles['menu-settings-link']} ${
          isOpenSubMenu ? styles['menu-settings-link-open'] : ''
        }`}
        aria-expanded={isOpenSubMenu}
        onClick={changeIsOpenSubMenu}
        onKeyDown={changeIsOpenSubMenu}
        role="button"
        tabIndex={0}
      >
        {route.icon && <i className={route.icon} />}
        <span>{t(`${route.title}`)}</span>
      </a>
      {isOpenSubMenu && (
        <ul className="sub-menu" aria-expanded="false">
          {route.items.map((sectionSubItem) => (
            <SidebarMenuItemLink
              key={sectionSubItem.title}
              route={sectionSubItem}
              onCloseSidebar={onCloseSidebar}
            />
          ))}
        </ul>
      )}
    </li>
  );
};
export default SidebarMenuItemLink;
