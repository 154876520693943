import React, { useContext, useState } from 'react';
import { MDBDataTable } from 'mdbreact';
import { Col, Dropdown, Row } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { useTranslation } from 'react-i18next';
import CustomSpinner from '../spinner';
import styles from './notification-dropdown.module.scss';
import { useCommandRegister } from '../../../hooks/useCommandRegister';
import getCommandTime from '../../helpers/getCommandTime';
import Badge from '../Badge';
import { SessionContext } from '../../context/session';

const maxBadgeNumber = 99;

const NotificationDropdown = () => {
  const { session } = useContext(SessionContext);
  const userId = session?.user?.uid;

  const [menu, setMenu] = useState(false);
  const { t } = useTranslation();
  const { commands, commandsCount, updateCommands } = useCommandRegister(
    userId,
  );

  const data = {
    columns: [
      {
        label: t('header.command'),
        field: 'command',
        width: 30,
        sort: 'disabled',
        headerStyle: 'active-row',
      },
      {
        label: t('header.status'),
        field: 'status',
        width: 30,
        sort: 'disabled',
      },
      {
        label: t('header.time'),
        field: 'executedDate',
        width: 30,
        sort: 'disabled',
      },
    ],
    rows: commands.map(
      (command) =>
        command && {
          command: (
            <h6 className="mt-0 mb-1 font-size-14">
              {t(`header.commands.${command.pattern}`)}
            </h6>
          ),
          status: (
            <div
              className={`${styles.status} font-size-12 text-muted ${
                command?.result === 'OK'
                  ? styles['status--success']
                  : styles['status--error']
              }`}
            >
              {!command?.result && (
                <div className={`${styles.spinner}`}>
                  <CustomSpinner />
                </div>
              )}
              <p className="mb-1">{command?.result || ''}</p>
            </div>
          ),
          executedDate: (
            <p className="mb-0">
              <span className={`${styles.time}`}>
                {getCommandTime(command.executedDate)}.
              </span>
            </p>
          ),
        },
    ),
  };

  return (
    <Dropdown
      show={menu}
      onToggle={() => setMenu(!menu)}
      className="dropdown d-inline-block"
    >
      <Dropdown.Toggle
        className={`${styles['dropdown-btn']} btn header-item noti-icon waves-effect`}
        as="button"
        id="page-header-notifications-dropdown"
      >
        <i className={`bx bx-bell ${commandsCount > 0 ? 'bx-tada' : ''}`} />
        <Badge number={commandsCount} max={maxBadgeNumber} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
        <div className="p-3">
          <Row className="align-items-center">
            <Col>
              <h6 className="m-0">{t('sidebar.notifications')}</h6>
            </Col>
            <div className="col-auto">
              <button
                type="button"
                className={styles['command-mark-as-read-btn']}
                onClick={updateCommands}
              >
                {t('header.markAsRead')}
              </button>
            </div>
          </Row>
        </div>

        <SimpleBar style={{ height: '230px' }}>
          <MDBDataTable
            key={commands.length}
            responsive={false}
            searching={false}
            data={data}
            noRecordsFoundLabel={t('header.commands.noMatchingRecordsFound')}
            theadColor="#333"
            hover
            className={styles['command-list']}
            paging={false}
          />
        </SimpleBar>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationDropdown;
