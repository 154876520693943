import { tenantRedirectTo } from '@digital.jit/auth/utils';
import getConfig from 'next/config';
import { removeSessionCookies } from '../context/session';
import { FirebaseApiInstance } from '../../api/firebase';
import { JitNextConfig } from '../../types/jit-next-config.interface';

export const logOutUser = async () => {
  const config = getConfig() as JitNextConfig;
  if (typeof window !== 'undefined') {
    removeSessionCookies();
    await FirebaseApiInstance.logOut();
    const currentUrl = new URL(window.location.href);
    const nextUrl = tenantRedirectTo(currentUrl.href, config);

    if (nextUrl !== window.location.href) {
      window.location.href = nextUrl;
    }
  }
};
