import routes from './routes';

const routesValues = Object.values(routes);

// const parentArray = ['sidebar.menu', 'sidebar.documents', 'sidebar.others'];
const parentArray = ['sidebar.menu'];

const newMenu = parentArray.map((title) => {
  const items = routesValues.filter((route) => route.sidebarTitle === title);

  const itemParent = items.filter((subRoute) => subRoute.subMenuParent);
  itemParent.forEach((parent) => {
    const newParent = parent;

    newParent.items = items.filter(
      (subRoute) => subRoute.subMenuTitle === parent.title,
    );
  });
  return {
    title,
    items: items.filter((el) => !el.subMenuTitle && !el.subChildLink),
  };
});

export default newMenu;
