import Head from 'next/head';
import React, { useState } from 'react';
import SimpleBar from 'simplebar-react';
import Header from '../header';

import Sidebar from '../sidebar';
import styles from './container.module.scss';

const Container = ({ children, title = 'JIT solution' }: any) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const openSidebar = (): void => {
    setSidebarOpen(true);
  };

  const closeSidebar = (): void => {
    setSidebarOpen(false);
  };

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>

      {/* <SimpleBar style={{ maxHeight: '100vh' }}> */}
      <div className={styles.container}>
        <Header {...{ openSidebar }} />

        <Sidebar open={sidebarOpen} closeSidebar={closeSidebar} />
        {children}
      </div>
      {/* </SimpleBar> */}
    </>
  );
};

export default Container;
