import React from 'react';

interface ICustomSpinner {
  className?: string;
}

const CustomSpinner = ({ className }: ICustomSpinner) => (
  <div className={`${className} lds-ring`}>
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default CustomSpinner;

CustomSpinner.defaultProps = {
  className: 'spinner',
};
