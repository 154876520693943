import React from 'react';
import { useTranslation } from 'react-i18next';
import SidebarMenuItemLink from '../sidebar-menu-item-link';
import { IRoute } from '../../interfaces/routes';
import styles from './sidebar-menu.module.scss';

interface ISidebarMenu {
  routes: IRoute[];
  onCloseSidebar: () => void;
}

const SidebarMenu = ({ routes, onCloseSidebar }: ISidebarMenu) => {
  const { t } = useTranslation();
  return (
    <ul className="metismenu list-unstyled">
      {routes.map((section) => (
        <React.Fragment key={section.title}>
          <li className={`menu-title ${styles.title}`}>
            {t(`${section.title}`)}
          </li>

          {section.items.map((sectionItem) => (
            <SidebarMenuItemLink
              key={sectionItem.title}
              route={sectionItem}
              onCloseSidebar={onCloseSidebar}
            />
          ))}
        </React.Fragment>
      ))}
    </ul>
  );
};
export default SidebarMenu;
