import React from 'react';
import SimpleBar from 'simplebar-react';
import menu from '../../constants/menu';
import SidebarMenu from '../sidebar-menu';
import styles from './sidebar.module.scss';

interface SidebarProps {
  open: boolean;
  closeSidebar: () => void;
}

const Sidebar = ({ open, closeSidebar }: SidebarProps) => (
  <>
    <div
      className={styles.sidebar + (open ? `  ${styles['sidebar-open']}` : '')}
    >
      <SimpleBar style={{ height: '100%' }}>
        <div id="sidebar-menu">
          <SidebarMenu routes={menu} onCloseSidebar={closeSidebar} />
        </div>
      </SimpleBar>
    </div>
    <button
      type="button"
      className={
        styles['sidebar-overlay'] +
        (open ? ` ${styles['sidebar-overlay-active']}` : '')
      }
      aria-labelledby="sidebar-overlay"
      onClick={closeSidebar}
    />
  </>
);

export default Sidebar;
